/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

/** The SideBarBtns class */
class SideBarBtns {
    /** Used to register the scroll event handler */
    Initialize() {
      /** When the user scrolls down 300px from the top of the document, show the buttons */
      window.addEventListener("scroll", this.ToggleButtons)    
      /** Event handler for scroll to top button */
      document.getElementById("scroll-btn").addEventListener("click", this.ScrollToTop);
    }
  
    /** Displays/Hides the buttons */
    ToggleButtons() {
      if(document.getElementById("scroll-btn")){
        var d = document.documentElement;
        var offset = d.scrollTop + window.innerHeight;
        var height = d.offsetHeight;

      /** If the current current scroll is 300px or more */
        if ((document.body.scrollTop > 300 || document.documentElement.scrollTop > 300) && offset <= height - 238) {
          /** The scroll to top button is displayed */
          document.getElementById("scroll-btn").style.display = "block";      
        } else {
          /** The scroll to top button is hidden */
          document.getElementById("scroll-btn").style.display = "none";    
        }

      }
    }
  
    /** When the user clicks on the button, scroll to the top of the document */
    ScrollToTop() {
       // cancel if already on top
    if (document.scrollingElement.scrollTop === 0) return;

        const cosParameter = document.scrollingElement.scrollTop / 2;
        let scrollCount = 0, oldTimestamp = null;

        function step (newTimestamp) {
            if (oldTimestamp !== null) {
                // if duration is 0 scrollCount will be Infinity
                scrollCount += Math.PI * (newTimestamp - oldTimestamp) / 10;
                if (scrollCount >= Math.PI) return document.scrollingElement.scrollTop = 0;
                document.scrollingElement.scrollTop = cosParameter + cosParameter * Math.cos(scrollCount);
            }
            oldTimestamp = newTimestamp;
            window.requestAnimationFrame(step);
        }
        window.requestAnimationFrame(step);
    }
  }

  const scrollToAnchor = () => {
    const hashesParts = window.location.hash.split('#');
    if (hashesParts[1]) {
      const anchorLink = hashesParts[1];
      var btn = document.createElement("a");
      btn.setAttribute("href",'#'+anchorLink.toLowerCase());
      document.body.appendChild(btn);
      setTimeout(function(){
        btn.click();
      }, 500);
    } else{
      window.scroll(0,0);
    }
  };
  
// Side menu functions
window.location.hash = window.decodeURIComponent(window.location.hash);

function evaluateActive(){
  let windowLocation = window.location.href.replace(/\/$/, "");
  let menuItems = document.querySelectorAll('.side-menu-link');
    menuItems.forEach(function (menuItem) { 
      let itemUrl = menuItem.href.replace(/\/$/, "");
      if(itemUrl === windowLocation){
        if (!menuItem.matches('.active')) {
          menuItem.className += " active";
        }
      }

    });
}


const updateActiveMenu = () => {
  let menuItems = document.querySelectorAll('.side-menu-link');
  let hashParts = window.location.hash.split('#');
  let newhash = '#'+hashParts[1];
  let nohash = window.location.href.replace(/\/$/, "");
  
  menuItems.forEach(function (menuItem) { 
    let menuHref = menuItem.href;
    let itemTarget =  menuHref.split('#');
    if (itemTarget[1]) { 
      let itemHash = '#'+itemTarget[1];
      if(itemHash === newhash){
        [].forEach.call(menuItems, function(el) {
            if(el === menuItem){
              el.classList.add("active");
            } else {
              el.classList.remove("active");
            }
        });
      }
    }  else {
      
      
      if(menuHref === nohash){
        [].forEach.call(menuItems, function(el) {
            if(el === menuItem){
              el.classList.add("active");
            } else {
              el.classList.remove("active");
            }
        });
        //window.scroll({top: 0, left: 0, behavior: 'smooth'});
        window.scroll(0,0);
      }
    }
  });
 }


 const getParents = function (elem, selector) {
  // Element.matches() polyfill
  if (!Element.prototype.matches) {
      Element.prototype.matches =
      Element.prototype.matchesSelector ||
      Element.prototype.mozMatchesSelector ||
      Element.prototype.msMatchesSelector ||
      Element.prototype.oMatchesSelector ||
      Element.prototype.webkitMatchesSelector ||
      function(s) {
        var matches = (this.document || this.ownerDocument).querySelectorAll(s),
          i = matches.length;
        while (--i >= 0 && matches.item(i) !== this) {}
        return i > -1;
      };
  }

  // Set up a parent array
  var parents = [];

  // Push each parent element to the array
  for ( ; elem && elem !== document; elem = elem.parentNode ) {
    if (selector) {
      if (elem.matches(selector)) {
        parents.push(elem);
      }
      continue;
    }
    parents.push(elem);
  }

  // Return our parent array
  return parents;

};

function openActiveParents(){
  const sideLinks = document.querySelectorAll(".side-menu-link");
    sideLinks.forEach(function(item) {
      if(item.classList.contains("active")){
        getParents(item, 'ul').forEach(function(parentUl) {
          if (!parentUl.matches('.show-me')) {
            parentUl.className += " show-me";
          }
          
        });
      }
    });
}


//Check if element is in viewport
function isInViewport(element) {
  let rect = element.getBoundingClientRect();
  let viewportHeight = window.innerHeight;
  let positionTop = rect.top/viewportHeight;
  let positionBottom = rect.bottom/viewportHeight;
  if (positionTop >= 0 && positionBottom <= 1) {
    return true;
  }
  else {
    return false;
  }
}

function isElementInViewport(el) {
  var rect = el.getBoundingClientRect();

  return rect.bottom > 0 &&
      rect.right > 0 &&
      rect.left < (window.innerWidth || document.documentElement.clientWidth)  &&
      rect.top < (window.innerHeight || document.documentElement.clientHeight);
}

//Update active menu depening on user scroll position
function updateMenuOnScroll(){
  let sideLinks = document.querySelectorAll('.side-menu-link');
  let topSection = document.querySelector('.top-blue-section');
  let pageRoot =  window.location.href.split("#")[0];
  pageRoot = pageRoot.replace(/\/$/, "");
  window.addEventListener('scroll', event =>{

    if (isInViewport(topSection)) {
      sideLinks.forEach(link2 => {
        link2.classList.remove("active");
        if(link2.href === pageRoot){
          link2.classList.add("active");
        }
      });
     
      openActiveParents();
    }


    sideLinks.forEach(link => {
      if(link.hash){
        let section = document.querySelector(link.hash);
        if(section && isElementInViewport(section)){
            sideLinks.forEach(link2 => {
              link2.classList.remove("active");
            });

            link.classList.add("active");
            openActiveParents();

            sideLinks.forEach(link3 => {
              if(link3.hash){
                let section2 = document.querySelector(link3.hash);
                if(section2 && isElementInViewport(section2)){ 
                 
                  link3.classList.add("active");

                }
              }
            }); 
        } 
      }   
    });
  });
}
// end side Menu functions

 // Tooltips User Events
function toolTipHover(){
  var tooltips = document.querySelectorAll('.glossary-term');
  tooltips.forEach(function (tooltip) {
  tooltip.addEventListener('mouseover', function hover(event) {
    var mousex = (event.clientX/window.innerWidth) * 100;    
    var mousey = (event.clientY/window.innerHeight) * 100;     

    for (var i = 0; i < tooltip.childNodes.length; i++) {
      if (tooltip.childNodes[i].className === "tooltip-item") {
       
        tooltip.childNodes[i].style.left = mousex + "%";
        tooltip.childNodes[i].style.top = mousey + "%";
        break;
      }        
  }

    if (mousex  >= 50){
      tooltip.classList.add('tooltip-left');
    } 

    if(mousey >= 50){
      tooltip.classList.add('tooltip-top');
    } else{
      tooltip.classList.add('tooltip-bottom');
    }
    tooltip.classList.add('show-tooltip');
  });

  tooltip.addEventListener('mouseout', function leave() {
    tooltip.classList.remove('show-tooltip', 'tooltip-left', 'tooltip-right', 'tooltip-top', 'tooltip-bottom');
   
  });

  window.addEventListener('scroll', event =>{ 
    tooltip.classList.remove('show-tooltip', 'tooltip-left', 'tooltip-right', 'tooltip-top', 'tooltip-bottom');
  });
});

}

function resolvePargraphsInTooltips(){
  var tooltips = document.querySelectorAll('.glossary-term .tooltip-item');
  tooltips.forEach(function (tooltip) { 

  });
}
// end tooltip User Events

function resolveFooterActiveHome(){
  let is_root = window.location.pathname === "/";
  if(is_root){
    const homeFooterLink = document.querySelector('.homepage-fooer-link');
    homeFooterLink.classList.add('active');
  }

}

exports.onRouteUpdate = () => {
  toolTipHover();
  resolvePargraphsInTooltips();
   /** The SideBarBtns object is created */
   let sidebarbtns = new SideBarBtns();
   /** If the ScrollToTop component exists on the page */
   if (document.getElementById("scroll-btn")) {
     /** The SideBarBtns object is initialized */
     sidebarbtns.Initialize();
   }
    console.log(window.location.href.replace(/\/$/, ""));
    updateMenuOnScroll();


    scrollToAnchor();
    updateActiveMenu();
    openActiveParents();
    resolveFooterActiveHome();
    
   setTimeout(function(){
    evaluateActive();
   }, 100)
  
}

  exports.shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition,
    }) => {
    const { pathname } = location
    // list of routes for the scroll-to-top-hook
    const scrollToTopRoutes = [`/pay-credits`, `/page-2`]
    // if the new route is part of the list above, scroll to top (0, 0)
    if (scrollToTopRoutes.indexOf(pathname) !== -1 && !window.location.hash) {
        window.scrollTo(0, 0)
    }

    return false
  }


  // Smooth Scroll Anchor links using npm smooth-scroll module
  if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#"]', {
        speed: 500,
        speedAsDuration: true,
        easing: 'ease'
    })
  }


    
     






